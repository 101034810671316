/*******************************************************************************
 * Styles for the base elements of the theme.
 ******************************************************************************/

//Added by Mark 
table {
  border: 1px solid black;
  border-collapse: collapse;
  align: center;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
}

th, td {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
}
th {
  background-color: var(--global-theme-color);
  opacity: 0.4;
  color: white;
}


h2, h3, h4 {
  margin-top: 1em;
}

// Typography

a, table.table a {
  color: var(--global-theme-color);
  &:hover {
    color: var(--global-theme-color);
    text-decoration: underline;
  }
  &:hover:after {
    width: 100%;
  }
}

// Math

.equation {
  margin-bottom: 1rem;
  text-align: center;
}

// Caption

.caption {
  font-size: 0.875rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  text-align: center;
}


// Profile

.profile {
  margin-left: 1rem;
  width: 100%;

  .address {
    margin-bottom: 5px;
    margin-top: 5px;
    font-family: monospace;
    font-size: 1.2rem;
    p {
      display: inline-block;
      margin: 0;
    }
  }
}

@media (min-width: 576px) {
  .profile {
    width: 30%;
    .address {
      p { display: block; }
    }
  }
}

.post-description {
  margin-bottom: 2rem;
  font-size: 0.875rem;
  a {
    color: inherit;
    &:hover {
      color: var(--global-theme-color);
      text-decoration: none;
    }
  }
}


// Navbar customization

.navbar {
  box-shadow: none;
  border-bottom: 1px solid $grey-color-light;
  opacity: 0.95;
  background-color: var(--global-bg-color);
}
.navbar.navbar-light {
  // Remove link decoration
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .navbar-brand {
    color: var(--global-text-color);
  }
  .navbar-nav .nav-item .nav-link {
    &:hover {
      color: var(--global-hover-color);
    }
  }
  .navbar-nav .nav-item.active>.nav-link {
      background-color: inherit;
      font-weight: bolder;
      color: var(--global-theme-color);
      &:hover {
        color: var(--global-hover-color);
      }
  }
  .contact-icon {
    font-size: 2rem;
    a {
      &:hover {
        color: var(--global-hover-color);
      }
    }
  }
}

.navbar-toggler {
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    background-color: $grey-color-dark;
    border-radius: 1px;
    margin-bottom: 4px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
}

.navbar-toggler.collapsed {
  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}


// News

.news table {
  border: 0px;
  border-collapse: collapse;
}
.news table td {
  font-size: 1rem;
  color: var(--global-text-color);
  vertical-align: middle;
  border-top: 1px solid #ddd;
  border-bottom: 0px;
  text-align: left;
}

.news table th {
  background-color: var(--global-bg-color);
  color: var(--global-theme-color);
  border-bottom: 0px;
  border-top: 1px dashed #ddd;
  border-right: 1px solid #ddd;
  align: right;
  vertical-align: top;
  font-weight: bold;
}

// Social (bottom)

.social {
  text-align: center;
  .contact-icon {
    font-size: 4rem;
    a {
      color: var(--global-icon-color);
      &:hover {
        color: var(--global-theme-color);
      }
    }
  }
  .contact-note {
    font-size: 0.8rem;
  }
}


// Footer
footer.fixed-bottom {
  color: var(--global-footer-text-color);
  background-color: var(--global-footer-bg-color);
  font-size: 0.75rem;
  .container {
    padding-top: 9px;
    padding-bottom: 8px;
  }
  a {
    color: var(--global-footer-link-color);
    &:hover {
      color: pink;
      text-decoration: none;
    }
  }
}

footer.sticky-bottom {
  border-top: 1px solid $grey-color-light;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 0.9rem;
}


// Blog

.header-bar {
  border-bottom: 1px solid $grey-color-light;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 5rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 5rem;
  }
}

.post-list {
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  li {
    border-bottom: 1px solid $grey-color-light;
    list-style: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .post-meta {
      color: $grey-color;
      font-size: 0.875rem;
      margin-bottom: 0;
    }
    a {
      color: var(--global-text-color);
      text-decoration: none;
      &:hover {
        color: var(--global-theme-color);
      }
    }
  }
}

.pagination .page-item.active .page-link {
  background-color: var(--global-theme-color);
  &:hover {
    background-color: var(--global-theme-color);
  }
}


// Distill

.distill {
  a:hover {
    border-bottom-color: var(--global-theme-color);
    text-decoration: none;
  }
}


// Projects

.projects {
  .grid-item {
    width: 250px;
    margin-bottom: 10px;
    a {
      color: black;
      text-decoration: none;
      &:hover {
        color: var(--global-theme-color);
      }
    }
  }
  .card img {
    width: 100%;
  }
}


// Publications
// inline is for when it is shown in a narrow column on about page
.publications-inline {
  margin-top: 2rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 2rem;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h2 {
    margin-bottom: 1rem;
    span {
      font-size: 1.5rem;
    }
  }
  h2.year {
    color: $grey-color;
    border-top: 1px solid var(--global-theme-color);
    padding-top: 1rem;
    margin-top: 2rem;
    margin-bottom: -2rem;
    text-align: right;
  }
  ol.bibliography {
    list-style: none;
    padding: 0;
    margin-top: 0;

    li {
      margin-bottom: 1rem;
      .title {
        font-weight: bolder;
      }
      .author {
        a {
          border-bottom: 1px dashed var(--global-theme-color);
          &:hover {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
        > em {
          border-bottom: 1px solid;
          font-style: normal;
        }
      }
      .links {
        a.btn {
          color: var(--global-text-color);
          border: 1px dashed var(--global-text-color);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          &:hover {
            color: var(--global-theme-color);
            border-color: var(--global-theme-color);
          }
        }
      }
      .hidden {
        font-size: 0.875rem;
        max-height: 0px;
        overflow: hidden;
        text-align: justify;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;

        p {
          line-height: 1.4em;
          margin: 10px;
        }
        pre {
          font-size: 1em;
          line-height: 1.4em;
          padding: 10px;
        }
      }
      .hidden.open {
        max-height: 100em;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;
      }
      div.abstract.hidden {
        border: dashed 1px white;
      }
      div.abstract.hidden.open {
        border-color: $grey-color;
      }
    }
  }
}
.publications {
  margin-top: 2rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 2rem;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h2 {
    margin-bottom: 1rem;
    span {
      font-size: 1.5rem;
    }
  }
  h2.year {
    color: $grey-color;
    border-top: 1px solid var(--global-theme-color);
    padding-top: 1rem;
    margin-top: 2rem;
    margin-bottom: -2rem;
    text-align: right;
  }
  ol.bibliography {
    list-style: none;
    padding: 0;
    margin-top: 0;

    li {
      margin-bottom: 1rem;
      .abbr {
        height: 2rem;
        margin-bottom: 0.5rem;
        abbr {
          display: inline-block;
          background-color: var(--global-theme-color);
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          a {
            color: white;
            &:hover {
              text-decoration: none;
            }
          }
        }
        .award {
          color: var(--global-theme-color) !important;
          border: 1px solid var(--global-theme-color);
        }
      }
      .title {
        font-weight: bolder;
      }
      .author {
        a {
          border-bottom: 1px dashed var(--global-theme-color);
          &:hover {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
        > em {
          border-bottom: 1px solid;
          font-style: normal;
        }
      }
      .links {
        a.btn {
          color: var(--global-text-color);
          border: 1px dashed var(--global-text-color);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          &:hover {
            color: var(--global-theme-color);
            border-color: var(--global-theme-color);
          }
        }
      }
      .hidden {
        font-size: 0.875rem;
        max-height: 0px;
        overflow: hidden;
        text-align: justify;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;

        p {
          line-height: 1.4em;
          margin: 10px;
        }
        pre {
          font-size: 1em;
          line-height: 1.4em;
          padding: 10px;
        }
      }
      .hidden.open {
        max-height: 100em;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;
      }
      div.abstract.hidden {
        border: dashed 1px white;
      }
      div.abstract.hidden.open {
        border-color: $grey-color;
      }
    }
  }
}

// Rouge Color Customization
code {
  color: var(--global-theme-color);
}
// Transitioning Themes
html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}
