/******************************************************************************
 * Content
 ******************************************************************************/

 body {
   padding-bottom: 70px;
   color: var(--global-text-color);
   background-color: var(--global-bg-color);
 }

 body.fixed-top-nav {
   // Add some padding for the nav-bar.
   padding-top: 56px;
 }

 body.sticky-bottom-footer {
   // Remove padding below footer.
   padding-bottom: 0;
 }

.container {
  max-width: $max-content-width;
}

// Profile
.profile {
  img {
    width: 100%;
  }
}

// TODO: redefine content layout.
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}
blockquote p {
  display: inline;
}


/******************************************************************************
 * Publications
 ******************************************************************************/

// TODO: redefine publications layout.


/*****************************************************************************
* Projects
*****************************************************************************/

// TODO: redefine projects layout.
